































.theme--light.v-footer {
  background-color: #21b6ff;
  color: white;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
